import React from 'react'
import Tags from './tags'
import { Link } from 'gatsby'

const PostPreview = ({post}) => {
    return (
        <Link className="blog-post-preview" to={post.frontmatter.path} key={post.frontmatter.id}>
            <h2>
                {post.frontmatter.title}
            </h2>
            <h3>{post.frontmatter.date}</h3>
            {post.frontmatter.tags &&
                <Tags tags={post.frontmatter.tags} />
            }
            <div className="blog-post-excerpt">
                <p>{post.excerpt}</p>
            </div>
            
        </Link>
    )
}

export default PostPreview
