import React, { useEffect } from 'react'
import SEO from '../components/seo'
import PostPreview from '../components/postPreview';

const BlogPage = ({data}) => {
    const  { edges: posts } = data.allMarkdownRemark;
    useEffect(() => {
        document.body.classList.add('blog-page');
        return () => {
            document.body.classList.remove('blog-page');
        }
    }, [])

    useEffect(() => {
        let blogLink = document.getElementsByClassName('page-link blog')
        blogLink[0].classList.add('current')
        return () => {
          blogLink[0].classList.remove('current')
        }
      }, [])
    return (
        <>
            <SEO title="Blog" /> 
            <div className="blog-posts">
                {posts
                    .filter(post => post.node.frontmatter.title.length > 0)
                    .map(({ node: post }, i) => {
                        return (
                          <PostPreview key={i} post={post} />
                        );
                    })}
            </div>
        </>
    )
}

export const pageQuery = graphql`
  query BlogQuery {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          excerpt(pruneLength: 250)
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            path
            tags
          }
        }
      }
    }
  }
`;

export default BlogPage
